import React from "react";
import { graphql } from "gatsby";
import { format } from "date-fns";

import { imageUrlFor } from "../lib/image-url";

import {
  mapEdgesToNodes,
  filterOutDocsWithoutSlugs,
  filterOutDocsPublishedInTheFuture,
  buildImageObj,
  getBlogUrl,
  cn
} from "../lib/helpers";
import BlogPostPreviewList from "../components/blog-post-preview-list";
import Container from "../components/container";
import GraphQLErrorList from "../components/graphql-error-list";
import SEO from "../components/seo";
import Layout from "../containers/layout";

export const query = graphql`
  fragment SanityImage on SanityMainImage {
    crop {
      _key
      _type
      top
      bottom
      left
      right
    }
    hotspot {
      _key
      _type
      x
      y
      height
      width
    }
    asset {
      _id
    }
  }

  query AboutPageQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
      description
      keywords
    }
  }
`;
const AboutPage = props => {
  const { data, errors } = props;

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }

  const site = (data || {}).site;
  // const postNodes = (data || {}).posts
  //   ? mapEdgesToNodes(data.posts)
  //       .filter(filterOutDocsWithoutSlugs)
  //       .filter(filterOutDocsPublishedInTheFuture)
  //   : [];

  if (!site) {
    throw new Error(
      'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
    );
  }

  return (
    <Layout screen>
      <SEO title={site.title} description={site.description} keywords={site.keywords} />
      <div className="flex flex-col h-full justify-end items-center">
        <div className=" w-full flex flex-col md:flex-row">
          <h1 className="w-full pb-3 md:pb-0 leading-none md:pr-5 md:w-auto tracking-wide uppercase font-display font-regular text-3xl xs:text-4xl sm:text-5xl">
            Freelance
            <br />
            Creative
            <br />
            Developer
          </h1>
          <p className="text-lg tracking-wider max-w-sm ">
            I make websites and write about how to make cool effects in them.
            <br />
            <br />
            Expect articles on WebGL, creative coding, and other things web.
          </p>
        </div>
      </div>
    </Layout>
  );
};

export default AboutPage;
